.btn {
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  line-height: initial;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  gap: 8px;
  border-radius: 2px;

  &.font-bold {
    font-weight: 700;
  }

  &.btn-md {
    font-size: 14px;
    height: 40px;
  }
}

.btn-outlined {
  background: #fff;
  border: 1px solid #dce1e6;

  &:hover {
    border: 1px solid #2b2c2d;
    background-color: #fff;
  }
}

.btn-primary {
  color: #fff;
  background: #18aa78;
  border: 1px solid #18aa78;

  &:hover {
    border: 1px solid #228765;
    background-color: #228765;
  }
}

.btn-danger {
  color: #fff;
  background: #ff3535;
  border: 1px solid #ff3535;

  &:hover {
    border: 1px solid #d23636;
    background-color: #d23636;
  }
}

.btn-ghost {
  color: #18aa78;
  background-color: #fff;
  border: 1px solid #dce1e6;

  &:hover {
    color: #18aa78;
    border: 1px solid #2b2c2d;
    background-color: #fff;
  }

  &:disabled {
    color: rgba(24, 170, 120, .4);
    border: 1px solid #dce1e6;
    background-color: #fff;
    &:hover {
      background-color: #fff;
    }
  }
}

.btn-secondary.btn {
  color: #2b2c2d;
  background-color: #e3e6e9;
  border: 1px solid #e3e6e9;


  &:hover {
    border: 1px solid #D4DAE0;
    background-color: #D4DAE0;
  }

  &:disabled,
  &:disabled:hover {
    color: rgba(43,44,45, 0.4);
    border: 1px solid rgba(227,230,233,0.4);
    background-color: rgba(227,230,233,0.4);
  }
}

