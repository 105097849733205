@import 'buttons';

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #2b2c2d;
}

img {
  max-width: 100%;
}

